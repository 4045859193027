<template>
    <div class="content-index">
        <div class="top-bg"></div>
        <div class="content-main">
            <Header></Header>
            <RecommendGame v-if="isPcShow" isPadding="false"></RecommendGame>
            <PCRecommendGame v-if="!isPcShow"></PCRecommendGame>
            <HotGame v-if="isPcShow"></HotGame>
            <PCHotGame v-if="!isPcShow"></PCHotGame>
        </div>
    </div>
</template>

<script>
import Header from "@/components/layout/Header"
import RecommendGame from "@/components/RecommendGame";
import HotGame from "@/components/HotGame";
import PCRecommendGame from "@/components/PCRecommendGame";
import PCHotGame from "@/components/PCHotGame";

export default {
    name: "Index",
    data() {
        return {
            isPcShow: false
        }
    },
    created() {
        document.title = "云即玩h5"
        this.isPc()
    },
    components: {
        Header,
        RecommendGame,
        HotGame,
        // PC端显示的游戏
        PCRecommendGame,
        PCHotGame
    },
    methods: {
        isPc() {
            if ((navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i))) {
                this.isPcShow = false
            }else{
                this.isPcShow =  true;
            }
        }
    }
}
</script>

<style scoped lang="less">
    .content-index {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;

        .top-bg {
            position: absolute;
            width: 100%;
            height: .45833rem;
            background: #212933;
            z-index: -1;
        }

        .content-main {
            width: 100%;
            z-index: 100;
        }
    }

    @media (max-width: 768px) {
        .content-index {
            .top-bg {
                height: 1.4rem;
            }
        }

    }
</style>