<template>
    <section class="recommend-game">
        <h1 class="title">推荐游戏</h1>

        <div class="game-main swiper-container" id="PCRecommendGame">
            <ul class="swiper-wrapper game-main-box">
                <li class="swiper-slide" v-for="item in recommendGameList">
                    <a href="#" class="game-img" >
                        <img :src="item.ico" >
                    </a>
                    <span>{{item.game_name}}</span>
                    <a href="#" class="open-game" @click="openGame(item.id)">开始</a>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
import Swiper from '../assets/js/idangerous.swiper2.7.6.min.js'
export default {
    name: "PCrecommendGame",
    data () {
        return {
            game_icon: 'http://app.yunjiwan.com',
            recommendGameList: []
        }
    },
    created() {
        if(this.Public.isHttps()) {
            this.game_icon = 'https://app.yunjiwan.com'
        }
        this.getRecommendGameData()
    },
    methods: {
        // 获取推荐游戏
        async getRecommendGameData() {
            this.h5Request({
                url: "/api/h5/recommend_list/"
            }).then(result => {
                if(result.data.code == 0)
                {
                    result.data.data.forEach((item, value) => {
                        item.ico = this.game_icon + item.ico;
                        this.recommendGameList.push(item)
                    })
                }
            })

            setTimeout(() => {
                new Swiper('#PCRecommendGame', {
                    slidesPerView : 'auto',
                    calculateHeight: true,
                    centeredSlides: true,
                    resistance : true,
                    grabCursor: true,
                });
            }, 500);
        },
        openGame(gameId) {
            this.$router.push('/phone?gameId='+gameId)
        }
    }
}
</script>

<style scoped lang="less">

    .title {
        position: relative;
        font-size: 0.48875rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #383838;
        box-sizing: border-box;
        padding-left: 0.2875rem;
        margin-bottom: .1rem;
    }

    .title::before {
        content: '';
        position: absolute;
        left: 0;
        width: 0.1333rem;
        height: 100%;
        background: #FF7431;
        opacity: 1;
        border-radius: 8px;
    }

    .recommend-game {
        padding: 10px;

        .game-main {
            width: 100%;
            margin-top: 15px;

            .game-main-box {
                display: flex;
                width: 100%;
                padding-right: 0 !important;
                padding-left: 0 !important;

                li {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    margin-right: .3rem;

                    .game-img {
                        display: block;
                        width: 1.6rem;
                        height: 1.6rem;
                        margin: 0 auto;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    span {
                        font-size: 14px;
                        margin-top: 5px;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }

                    .open-game {
                        height: 30px;
                        line-height: 30px;
                        margin-top: 5px;
                        border-radius: 8px;
                        background: #FF7431;
                        color: #fff;
                        font-size: 14px
                    }
                }
            }
        }
    }
</style>