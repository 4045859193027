<template>
    <section class="hot-game">
        <section class="game-main">
            <el-row :gutter="20">
                <el-col class="game-main-right" :span="18">
                    <h1 class="title">热门游戏</h1>
                    <div class="game-main-box">
                        <div class="game-item" v-for="item in hotGameList">
                            <el-avatar :size="100" shape="square">
                                <img :src="item.ico"/>
                            </el-avatar>
                            <div class="game-info">
                                <h2 class="game-info-title">{{item.game_name | namelize}}</h2>
                                <p class="game-info-p">{{item.game_desc | capitalize}}</p>
                            </div>
                            <a class="game-btn" :href="'https://h5.yunjiwan.com/detail?gameId='+item.id" v-if="Public.isHttps() == true">
                                打开
                            </a>
                            <a class="game-btn" :href="'http://h5.yunjiwan.com/detail?gameId='+item.id" v-else>
                                打开
                            </a>
                        </div>
                        <el-pagination
                            background
                            layout="prev, pager, next"
                            :total="totals"
                            :page-size="18"
                            @size-change="changPage"
                            @current-change="changPage">
                        </el-pagination>
                    </div>
                </el-col>
                <el-col class="game-main-left" :span="6">
                    <h1 class="title">排行游戏</h1>
                    <div class="main-left-box">
                        <div class="rank-game-list">
                            <div class="rank-item" v-for="item in rankGameList">
                                <el-avatar :size="100" shape="square">
                                    <img :src="item.ico"/>
                                </el-avatar>
                                <div class="game-info">
                                    <h2 class="game-info-title">{{item.game_name}}</h2>
                                    <p class="game-info-p">{{item.game_desc | capitalize}}</p>
                                </div>
                                <a class="game-btn" :href="'https://h5.yunjiwan.com/detail?gameId='+item.id" v-if="Public.isHttps() == true">
                                    打开
                                </a>
                                <a class="game-btn" :href="'http://h5.yunjiwan.com/detail?gameId='+item.id" v-else>
                                    打开
                                </a>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </section>
    </section>
</template>

<script>
export default {
    name: "HotGame",
    data() {
        return {
            game_icon: 'http://app.yunjiwan.com',
            hotGamePage: 1,
            hotGameSize: 18,
            hotGameList: [],
            totals: 0,
            rankGameList: []
        }
    },
    created() {
        if(this.Public.isHttps()) {
            this.game_icon = 'https://app.yunjiwan.com'
        }
        this.getHotGameData()
        this.getRankGameData()
    },
    methods: {
        // 获取游戏热门的数据
        async getHotGameData() {
            this.h5Request({
                url: '/api/h5/game_list/?page=' + this.hotGamePage + '&size=' + this.hotGameSize
            }).then(result => {
                if(result.data.code == 0)
                {
                    this.hotGameList = []
                    this.totals = result.data.data.total
                    result.data.data.items.forEach((item, value) => {
                        item.ico = this.game_icon + item.ico;
                        this.hotGameList.push(item)
                    })
                }
            })
        },
        // 开始游戏
        openGame (gameId) {
            this.$router.push('/detail?gameId='+gameId)
        },
        // 点击热门游戏的数据分页变化游戏数据
        changPage(page) {
            this.hotGamePage = page
            this.getHotGameData()
        },
        async getRankGameData() {
            this.h5Request({
                url: '/api/h5/game_rank/?page=1&size=6'
            }).then(result => {
                if(result.data.code == 0)
                {
                    console.log()
                    result.data.data.items.forEach((item, value) => {
                        item.ico = this.game_icon + item.ico;
                        this.rankGameList.push(item)
                    })
                }
            })
        }
    },
    filters: {
        capitalize (value) {
            const cNumber = 27// 设置限定字数

            if (!value) return ''

            if (value.length > cNumber) {
                return value.slice(0, cNumber) + '...'
            }
            return value
        },
        namelize (value) {
            const cNumber = 5// 设置限定字数

            if (!value) return ''

            if (value.length > cNumber) {
                return value.slice(0, cNumber) + '...'
            }
            return value
        }
    }
}
</script>

<style scoped lang="less">

    .el-pager .active {
        background-color: #FF7532 !important;
    }

    .el-pagination {
        position: absolute;
        bottom: .1rem;
        right: 0;
    }

    .game-info {
        width: calc(100% - 0.833rem);
        margin-left: 12px;
        .game-info-title {
            width: 0.833rem;
            font-size: 0.125rem;
            font-family: Microsoft YaHei;
            color: #212933;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        .game-info-p {
            margin-top: 0.099rem;
            font-size: 0.0625rem;
            font-family: Microsoft YaHei;
            color: #212933;
            -webkit-line-clamp: 3;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }
    }

    .game-btn {
        position: absolute;
        right: 10px;
        width: 0.23rem;
        height: 0.14rem;
        line-height: 0.14rem;
        border: 1px solid #FF7431;
        border-radius: 4px;
        text-align: center;
        font-size: 0.0625rem;
        color: #FF7431;
        cursor: pointer;
    }

    .hot-game {
        box-sizing: border-box;
        padding: 0 1.35rem;
        margin-top: 0.3125rem;

        .title {
            position: relative;
            font-size: 0.1875rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #151C24;
            box-sizing: border-box;
            padding-left: 0.125rem;
            margin-bottom: .1rem;
        }

        .title::before {
            content: '';
            position: absolute;
            left: 0;
            width: 0.04rem;
            height: 100%;
            background: #FF7431;
            opacity: 1;
            border-radius: 8px;
        }

        .game-main {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            .el-row {
                width: 100%;
                margin: 0 !important;

                .game-main-right {
                    padding-left: 0 !important;
                    .game-main-box {
                        position: relative;
                        display: flex;
                        flex-wrap: wrap;
                        padding-bottom: 0.2625rem;
                        padding-top: 0.1rem;
                        padding-left: .1rem !important;
                        padding-right: .1rem !important;
                        margin-bottom: .1rem;
                        background: #FFFFFF;

                        .game-item {
                            display: flex;
                            position: relative;
                            width: 33.333%;
                            padding: 0 12px;
                            box-sizing: border-box;
                            margin-bottom: 20px;

                            .el-avatar {
                                height: 0.521rem !important;
                                width: 0.521rem !important;
                                line-height: 0.521rem !important;
                            }
                        }
                        .game-item:nth-child(1), .game-item:nth-child(3n+1){
                            padding-left: 0;
                        }

                        .game-item:nth-child(3n+0) {
                            padding-right: 0;
                        }
                    }
                }

                .game-main-left {
                    box-sizing: border-box;
                    padding-left: 10px;
                    padding-right: 0 !important;

                    .main-left-box {
                        width: 100%;
                        height: 100%;
                        background: #FFFFFF;
                        box-sizing: border-box;
                        padding-left: 10px;
                        padding-top: .1rem;
                        padding-bottom: 0.09rem;

                        h3 {
                            font-size: 0.167rem;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #151C24;
                            margin-bottom: 0.125rem;
                        }

                        .rank-game-list {
                            width: 100%;

                            .rank-item {
                                position: relative;
                                display: flex;
                                margin-bottom: 0.125rem;

                                .el-avatar {
                                    height: .521rem !important;
                                    width: .521rem !important;
                                    line-height: .521rem !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1300px) {
        .game-info .game-info-p {
            -webkit-line-clamp: 2;
        }
    }
</style>