<template>
    <section class="hot-game">
        <!-- 选项框 -->
        <section class="nav-select">
            <ul id="nav-select-ul">
                <li :class="[selected == 1?'nav-select-item':'']" @click="selectedChang(1)">热门游戏</li>
                <li :class="[selected == 2?'nav-select-item':'']" @click="selectedChang(2)">排行</li>
                <!--          <li :class="[selected == 3?'nav-select-item':'']" @click="selectTab(3)">礼包</li>-->
            </ul>
        </section>

        <!-- 选项卡内容 -->
        <section class="nav-content">
            <!-- 热门游戏 -->
            <div class="nav-content-box" :class="[selected == 1?'nav-conten-selected':'']">
                <ul class="nav-content-ul" id="hot-ul">
                    <li class="nav-content-item" v-for="item in hotGameList">
                        <a @click="openGame(item.id)" class="game-box">
                            <div class="game-img">
                                <img :src="item.ico" alt="">
                            </div>
                            <div class="game-info">
                                <h3>{{item.game_name}}</h3>
                                <p>{{item.game_desc}}</p>
                            </div>
                        </a>
                        <div class="open-btn">
                            <a @click="openGame(item.id)">开始游戏</a>
                        </div>
                    </li>
                </ul>
                <div class="loaded-main" v-if="hotGameList.length > 0">
                    <button @click="hotClick">点击加载更多</button>
                </div>
            </div>
            <!-- 排行游戏 -->
            <div class="nav-content-box" :class="[selected == 2?'nav-conten-selected':'']">
                <ul class="nav-content-ul">
                    <li class="nav-content-item" v-for="item in rankGameList">
                        <a @click="openGame(item.id)" class="game-box">
                            <div class="game-img">
                                <img :src="item.ico" alt="">
                            </div>
                            <div class="game-info">
                                <h3>{{item.game_name}}</h3>
                                <p>{{item.game_desc}}</p>
                            </div>
                        </a>
                        <div class="open-btn">
                            <a @click="openGame(item.id)">开始游戏</a>
                        </div>
                    </li>
                </ul>
                <div class="loaded-main" v-if="rankGameList.length > 0">
                    <button id="hot-loaded" @click="rankClick">点击加载更多</button>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
export default {
    name: "PCHotGame",
    data () {
        return {
            game_icon: 'http://app.yunjiwan.com',
            selected: 1,
            // 热门游戏列表
            hotGameList: [],
            hotGamePage: 1,
            rankGameList: [],
            rankGamePage: 1,
        }
    },
    created() {
        if(this.Public.isHttps()) {
            this.game_icon = 'https://app.yunjiwan.com'
        }
        // 获取热门游戏
        this.getHotGameData();
    },
    methods: {
        // 获取热门游戏
        async getHotGameData() {
            this.h5Request({
                url: '/api/h5/game_list/?page=' + this.hotGamePage
            }).then(result => {
                if(result.data.code == 0)
                {
                    result.data.data.items.forEach((item, value) => {
                        item.ico = this.game_icon + item.ico;
                        this.hotGameList.push(item)
                    })
                    this.hotGamePage += 1;
                }
            })
        },
        // 点击热门游戏加载更多
        hotClick () {
            this.getHotGameData();
        },
        rankClick() {
            this.getRankGameData()
        },
        openGame(gameId) {
            this.$router.push('/phone?gameId='+gameId)
        },
        // 获取排行游戏
        async getRankGameData() {
            this.h5Request({
                url: '/api/h5/game_rank/?page='+this.rankGamePage
            }).then(result => {
                if(result.data.code == 0)
                {
                    result.data.data.items.forEach((item, value) => {
                        item.ico = this.game_icon + item.ico;
                        this.rankGameList.push(item)
                    })
                    this.rankGamePage += 1;
                }
            })
        },
        selectedChang(key) {
            this.selected = key
            switch (key) {
                case 1:
                    if(this.hotGameList.length > 0) {
                        return
                    }
                    this.getHotGameData()
                    break
                case 2:
                    if(this.rankGameList.length > 0) {
                        return
                    }
                    this.getRankGameData()
                    break
            }
        }
    }
}
</script>

<style scoped lang="less">
    .nav-conten-selected {
        display: block !important;
    }

    .game-box {
        display: flex;
    }

    .game-box .game-img img {
        width: 1.5rem;
        height: 1.5rem;
    }

    .game-box .game-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 10px;
        padding-right: 10px;
    }

    .game-box .game-info h3 {
        font-size: .386667rem;
        font-weight: 700;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .game-box .game-info p{
        font-size: .296667rem;
        margin-top: 5px;
        color: #999;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .open-btn {
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .open-btn a {
        display: inline-block;
        width: 1.5rem;
        height: 30px;
        line-height: 30px;
        margin-top: 10px;
        border-radius: 8px;
        background: #FF7431;
        color: #fff;
        font-size: 14px;
        padding: 0 10px;
        text-align: center;
    }

    .loaded-main {
        padding: .3rem 1rem;
        text-align: center;
    }

    .loaded-main button {
        display: inline-block;
        height: 38px;
        line-height: 38px;
        padding: 0 18px;
        background-color: #FF7431;
        color: #fff;
        white-space: nowrap;
        text-align: center;
        font-size: 14px;
        border: none;
        border-radius: 100px;
        cursor: pointer;
    }

    .hot-game {
        .nav-select {
            width: 100%;
            border-top: 1px solid #FF7431;
            border-bottom: 1px solid #FF7431;
            box-sizing: border-box;
            padding: 7px 10px;
            margin-top: 10px;
            background: #FFFFFF;

            #nav-select-ul {
                display: flex;
                width: 100%;

                li {
                    width: 30%;
                    text-align: center;
                    height: 30px;
                    line-height: 30px;
                    box-sizing: border-box;
                    padding: 0 10px;
                    border-radius: 30px;
                    transition: all .3s;
                    font-size: .296667rem;
                }

                .nav-select-item {
                    background-color: #FF7431;
                    color: #FFF9EC;
                }
            }
        }

        .nav-content {
            width: 100%;
            background: #FFFFFF;

            .nav-content-box {
                display: none;

                .nav-content-ul {
                    li {
                        display: flex;
                        justify-content: space-between;
                        box-sizing: border-box;
                        padding: 15px 10px 0 10px;
                    }
                }
            }
        }
    }
</style>